@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

//variables
$theme1: #009fe3 !default;
$theme2: #e85212 !default;
$theme3: #3bab36 !default;
$theme1light: #009fe3de !default;
$whitelight: #ffffffde !default;

$success: $theme3;
$dark: $theme1;
$primary: $theme1 !default;
$primary-faded : lighten($primary, 25%);
$secondary: $theme2 !default;
$gray-light: #F7F7F7;

$frosting: #ffffff60;

$standard-font-family: 'Arial';
$headings-font-family: 'Arial';

$border-width-mid: 4px !default;
$border-radius: 15px;
$border-radius-mid: 30px;
$border-radius-lg: 60px;
$btn-border-radius: 20px;

$hamburger-layer-color: $dark !default;
$hamburger-active-layer-color: $dark !default;

$themeColors: (
        "1": $theme1,
        "2": $theme2,
        "3": $theme3,
);

@import '@/src/scss/main.scss';

// Merge the maps
$themeColors: map-merge($themeColors, $default-theme-colors);

* {
  font-family: $standard-font-family;
}


@each $themeColors, $i in $themeColors {

  .color-theme#{$themeColors} {
    color: $i !important;
  }
  .bg-theme#{$themeColors} {
    background-color: $i !important;
    color: white;
  }
  .border-theme#{$themeColors} {
    border-color: $i !important;
    border-width: $border-width-mid !important;
  }
  .btn-theme#{$themeColors} {
    background-color: $i !important;
    color: white;
    &:hover {
      filter: brightness(85%);
      color: white;
    }
  }
}

.list-group-item {
  border-width: 2px;
}

.h5,h3,h2,.h2,h1 {
  color: $primary;
  font-weight: 600 !important;
  a {
    color: $dark
  }
}

.card-subtitle {
  color: $primary !important;
  font-width: 600;
}

.card-title {
  color: black;
  font-weight: 600;
  a {
    color: black;
  }
}

.publication {
  border-left: solid $theme2 $border-width-mid;
  font-weight: 600;
  color: $theme2;
  font-size: 1.3em;
}

.part-nav {
  li {
    a, list-group-item {
      color: black;
    }
  }
}

.heading {
  padding:10px 25px 9px 25px;
  border-radius: $border-radius-lg;
  border: solid $border-width-mid white;
  background-color: $theme2;
  margin-top: -45px;
  width: max-content;
  @include media-breakpoint-down(md) {
    margin-top: -38px;
  }
  h1 {
    text-transform: uppercase;
    color: white;
    margin: 0;
    font-size: 2em;
    @include media-breakpoint-down(md) {
      font-size: 1.3em;
    }
  }
}

form#CustomerForm-Login {
  small.text-muted a {
    color: $text-muted;
  }
}

.spacer {
  margin-top: 0;
  border: solid $border-width-mid $theme1;
  border-radius: $border-radius;
}

.card {
  .d-grid {
    border: solid $theme2;
    background-color: $theme2;
    border-radius: $border-radius;
  }
  .svg-title {
    color: white;
  }
  h6 {
    color: black;
  }
  .fa-calendar-alt {
    font-size: 1.5em;
    color: gray;
  }
  small {
    color: black;
  }
}

.text-light {
  color: lightgrey;
}

.home-banner, .footer-banner {
  .home-banner-item {
    border-radius: $border-radius;
    border: solid $border-width-mid $theme2;
  }
  .image-overlay {
    background-color: $theme2;
  }
  p {
    text-transform: uppercase;
    font-weight: 600;
  }
}

.w-500 {
  max-width: 550px;
}

.text-bottom {
  p {
    line-height: 2.2em;
  }
  @include media-breakpoint-down(md) {
    margin-top: 3em !important;
  }
}

.modal-content {
  border-radius: $border-radius;
}

.dropdown-item {
  padding-left: 0.8rem !important;
}

.intro-banner {
  .btn {
    font-weight: 600;
  }
  @include media-breakpoint-down(sm) {
    background-image: none !important;
    .container-fluid {
      background-color: $theme1 !important;
    }
  }
}

.flavourKolk {
  right: 0;
  img {
    opacity: 10%;
    height: 450px;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.bannerspot {
  border-radius: $border-radius;
  border: solid $border-width-mid $theme1;
}

.home-banner:first-child, .footer-banner:first-child {
  .home-banner-item {
     border: solid $border-width-mid $theme3;
  }
  .image-overlay {
    background-color: $theme3;
  }
}
.home-banner:last-child {
    .home-banner-item {
      border: solid $border-width-mid $theme1;
    }
    .image-overlay {
      background-color: $theme1;
    }
}

footer {
  z-index: 2;
  position: relative;
  background-color: $gray-light;
  a {
    color: $theme1;
  }
}

.hamburger {
  margin-left: auto;
  padding: 14px 14px;
}

div.photo-banner {
  position: relative;
  top: 0;
  left: 0;
  margin-top: -66px;
  #bottom-layer {
    position: relative;
    top: 0;
    left: 0;
    max-height: 650px;
    min-height: 250px;
    width: 100%;
  }
  .banner-max {
    max-height: 800px;
    h1 {
      text-shadow: 0 0.063em 0.063em*2 rgba(0,0,0,.3); color: $white;
    }
  }
  #top-layer {
    z-index: 1;
    position: absolute;
    bottom: -2px;
    left: 0;
    //max-height: 150px;
    width: 100%;
  }
  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
}

div.social-icons {
  margin-left: 10px;
  display: inline-block;

  a {
    background-color: $gray-light;
    text-align: center;
    color: black;
    font-size: 14px;
    border-radius: 15px;
    align-content: center;
    display: inline-block;
    margin-right: 4px;
    font-size: 1.4em;
    opacity: 90%;

    &:hover {
      background-color:
              none;
      color: $theme2;
    }
  }
}


.top-menu {
  color: white;
  font-weight: 400;
  font-size: 16px;

  ul {
    margin-bottom: 0;

    li {
      margin: 10px 10px 10px 10px;
      list-style: none;
      color: white;

      div.social-icons {
        margin-left: 10px;
        display: inline-block;

        a {
          background-color: white;
          text-align: center;
          padding-top: 6px;
          color: $theme1 !important;
          font-size: 11px;
          border-radius: 15px;
          align-content: center;
          display: inline-block;
          width: 25px;
          height: 25px;

          i {
            font-size: 14px;
          }

        }

        :hover {
          background-color: #dfdfdf;
        }

      }

      a {
        color: $black;
        text-decoration: none;

      }

      .active {
        a {
          color: $info;
        }
      }
    }
  }
}

.mobile-hamburger-color {
  @extend .text-dark;
}

.navbar-dark .navbar-nav .nav-link {
  color: $white;
}

.nav-cart {
  @extend .position-relative;
  .nav-link {
    @extend .px-lg-3;
    &.disabled {
      .badge {
        display: none;
      }
    }
  }
  .badge {
    @extend .ml-2, .ml-lg-0;
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 8px;
      right: 0;
    }
  }
}

small {
  .nav-cart {
    .badge {
      top: -2px !important;
      right: -7px !important;
    }
  }
}

$owl-image-path: '~owl.carousel/src/img/';
@import "~owl.carousel/src/scss/owl.carousel";


.flex-wrapper {
  display: flex;
  min-height: 70vh;
  flex-direction: column;
  justify-content: space-between
}

.wrapper {
  margin-bottom: -2rem;
}

.content-wrapper {
  padding: 1.5rem 2rem 16rem 2rem;
  @include media-breakpoint-down(md) {
    padding: 1.5rem 1rem 10rem 1rem;
  }
  h1 {
    margin-top: 3rem !important;
  }
}

.border-radius-top-1 {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.border-radius-bottom-1 {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.content-news-detail-wrapper {
  img {
    width: 100%;
    height: auto;
  }
}

article {
  border-radius: 15px;
  .border-rad-bottom {
    border: 1px solid $dark;
    border-radius: 0 0 15px 15px;
  }

  .border-rad-top {
    border-radius: 15px 15px 0 0;
  }
}

.dropdown-menu.sub-dropdown.show {
  background: #55add3;
}

.footer-heading {
  color: white;
}

.navbar {
  padding: 0 8px;
  z-index: 999;
  li.nav-item {
    margin-bottom: 6px;
    a {
      padding: 21px 0 15px 0;
      color: black;
    }
    &.active:after {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 6px solid $theme1;
      margin-top: -6px;
      position: relative;
      bottom: -6px;
      opacity: 1;
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }
    &:not(.active):hover:after{
      content: '';
      display: block;
      width: 100%;
      border-bottom: 6px solid $theme3;
      margin-top: -6px;
      position: relative;
      bottom: -6px;
      opacity: 1;
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }
  }
}

.bg-header-bar {
  background: $frosting;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);
  color: black;
}


figure.img-cover {
  width: 100%;
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(md) {
    height: 170px;
  }
  @include media-breakpoint-down(sm) {
    height: 130px;
  }
}

th {
  background: none !important;
  border-top: none !important;
  border-bottom: 2px solid $primary !important;
}

.rounded-img {
  border: solid $border-width-mid $theme2;
  border-radius: $border-radius;
  margin-bottom: .5rem;
}

.hover--box-shadow {
  text-decoration: none !important;
  img {
    transition: all 0.3s;
  }
  &:hover {
    img {
      box-shadow: 5px 5px 15px 5px #0000000f;
    }
  }
}

.menu-block {
  border: 2px solid $white;
  border-radius: 15px;
  align-content: center;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 15px;
  transition: all 0.3s;

  &:hover {
    background-color: $dark;
  }

  &.active {
    background-color: $dark;
  }

  a {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: $white;
    width: 100px;
    height: 100px;

    i.fas {
      display: inline-block;
      width: 100%;
    }

    &:hover {
      text-decoration: none;
    }

  }
}
.space-logo {
  @include media-breakpoint-up(md){
    margin-top: 7rem;
  }
}

@include media-breakpoint-up(md) {
  .position-md-static {
    position: static !important;
  }
}

.navbar-brand {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  @include media-breakpoint-down(md) {
    margin: 0;
  }
  img {
    height: 7rem;
    position: absolute;
    padding: 6px;
    z-index: 999;
    max-width: none;
    @include media-breakpoint-down(md) {
      height: 4rem;
      padding-top: 0;
    }
  }
}

.b-radius {
  border-radius: $border-radius;
}

.content-section {
  .img {
    background-position: center;
    height: 300px;
    background-size: cover;
  }
}

.overlay-color {
  background-color: $theme2;
  border-radius: 0 $border-radius-mid $border-radius-mid 0;
  width: 23%;
  margin-top: -30px;
  height: 360px;
  @include media-breakpoint-up(xl) {
    width: 30%;
  }
  @include media-breakpoint-up(xxl) {
    width: 35%;
  }
}

.banner-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .container-fluid {
    background: rgb(0,159,227);
    background: linear-gradient(90deg, rgba(0,159,227,0.12) 40%, rgba(0,159,227,1) 60%);
    .col {
      padding: 0;
    }
    h2 {
      background-color: white;
      padding: 10px;
      padding-left: 22px;
      border-radius: 30px 0 0 30px;
    }
    p {
      font-size: 1.3em;
    }
    @include media-breakpoint-down(md) {
      h2 {
        font-size: 1.4em;
      }
      p {
        font-size: 1em;
      }
    }
  }
}


#form-container {
  margin-left: inherit !important;
  margin-right: inherit !important;
}


footer {
  .social-icons {
    margin: 0;
  }
}

#invoices div.card-header {
  background-color: $info;
  color: $white;
  border-radius: 0;
}

#invoices div.card-footer {
  background-color: $white;
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  border-radius: 0;
}


#invoices div.card-header span.js-resend-invoice {
  color: $white !important;
}

@include media-breakpoint-up(sm) {
  #side-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px;
    z-index: -1;
  }

  #side-menu-bg {
    height: 100vh;
    background-color: $info;
  }

  .navbar {
    z-index: 2;
  }

  #topNav {
    z-index: 2;
  }
}

#topNav li a{
  color: white;
  &:hover {
    color: #dfdfdf;
  }
}

#topNav li.active a{
  color: white;
}

.part-category-nav {
  h3 {
    @extend .text-primary;
  }
}

.planner-home, .planner-list {
  .btn-outline-primary {
    @extend .btn-secondary;
  }
}

.planner-list {
  .custom-select, .form-control {
    @extend .text-primary;
  }
}

.login--button {
  @extend .btn-theme3;
  @extend .border-0;
}

.quick-menu-item {
  @extend .mt-2;
  .img-fluid {
    @extend .p-lg-3;
  }
}

.follow-us {
  display: none;
}

#footer-img {
  z-index: -2;
  position: relative;
}
.image-overlay {
  background-color: $primary;
  position: relative !important;
  bottom: 0;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -25px;
  border-radius: $border-radius;
}

.navbar-nav .dropdown-menu {
  background-color: #e9ecef;
  color: $white;
  border-radius: 0px;
  top: 64px;
  padding: 0;
  border: none;
  a {
    padding: 16px 8px !important;
    color: $white;

    &:hover {
      background-color: $primary;
      color: white;
    }
  }
}

.hero-cta {
  z-index: 1;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.2) 35%, rgba(0, 0, 0, 0) 100%);
  text-align: center !important;
  .cta-wrapper {
    bottom: 45%;
    @include media-breakpoint-down(md) {
      bottom: 40%;
    }
    @include media-breakpoint-down(md) {
      h2 {
        font-size: 17px;
      }
    }
    @include media-breakpoint-down(sm) {
      h1 {
        font-size: 2.1em;
      }
    }
  }
}

.sticky-top {
  z-index: 0;
}

.quick-menu-item {
  word-break: break-all;
  .row {
    &:hover {
      filter: contrast(0.5);
    }
  }
  @include media-breakpoint-down(sm) {
    font-size: 1.5em;
  }
}

.quick-menu {
  background: url('../img/sport.jpg');
  background-size: cover !important;
  background-position: center;
  font-weight: 600;
  .bg-quick {
    background-color: $theme1light;
  }
  .container {
    padding-top:4em;
    padding-bottom: 2em;
  }
}

.alt-menu, .theme-border {
  border-top: solid 9px $theme1;
  .justify-content-between {
    .row {
      &:hover {
        filter: contrast(0.5);
      }
    }
  }
  .bg-quick {
    background-color: $whitelight;
  }
  .text-md-center {
    color: black;
  }
}

.sidebar-nav {
  h1,h2,h3,h4,h5,h6 {
    color: $white;
  }
  color: $white;
}

.btn-login {
  background-color: $dark;
}

div#Rooster {
  padding-top: 0rem !important;
  select, input {
    border-radius: $border-radius-lg;
  }
  h3 {
    font-size: 1.4rem;
    margin-bottom: 0.7em;
  }
  h4 {
    color: $primary;
  }
}

.btn {
  text-transform: uppercase;
  font-weight: 600 !important;
}

.sidebar {
  min-width: 500px;
  max-width: 500px;
  background: $primary;
  transition: all .3s;
  position: relative;
  z-index: 1;
  min-height: 90vh;
  @include media-breakpoint-down(md) {
    min-width: 100%;
    min-height: unset;
  }
  .sidebar-nav {
    padding: 0 6rem;
    @include media-breakpoint-down(md) {
      padding: 0 1rem;
    }
  }
}

.sticky-top{
  .card-body{
    padding: 0;
  }
}

.popover {
  border-radius: 0 !important;
  @include media-breakpoint-down(sm) {
    left: -90px;
    .arrow{
      left: 170px;
    }
  }
  h3 {
    border-radius: 0;
  }
}

// Calendar
$space: 1.5rem;

.calendar {
  margin: auto (-$space);
  box-shadow: 0 ($space * .125) ($space * .25) rgba(0, 0, 0, .025);


  .owl-carousel:not(.owl-loaded) {
    //padding: 0 1.5rem;
    > .item:not(:first-child) {
      display: none;
    }
  }
  .owl-carousel .item {
    position: relative;
    z-index: 100;
    -webkit-backface-visibility: hidden;
  }

  li figure {
    padding-top: 0; height: $space * 10;
    border-radius: 12px;
    overflow: hidden;
    background-size: cover;
    .inner {
      position: relative;
      text-align: center; text-shadow: 0 0.063em 0.063em*2 rgba(0,0,0,.5); color: $white;
      h5, .h5 { font-size: 1.5rem; color: white; }
    }
    .inner-wrapper {
      display: flex; align-items: center; justify-content: center;
      z-index: 1;
      height: 100%;
      background: rgba(0,0,0,.5);
      border-radius: 12px;
      overflow: hidden;
    }
    .inner,
    .inner-wrapper { @include transition(); }
  }



  .extrainfo {
    padding: $space * .5;
    a { line-height: $space; }
  }

  li figure:hover {
    .inner-wrapper {
      opacity: 0;
    }
  }
}

.small {
  font-size: 0.8rem;
}

.owl-nav {
  position: absolute;
  top: -2.25rem;
  right: 9px;
  left: 9px;
  font-size: .875rem;
  text-align: right;
  @extend .px-3;
}

.owl-carousel {
  padding: 0 9px 0 9px;
}

.calendar-item {
  border: solid 2px $primary;
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 12px;
}

.double-spaced {
  height: 58px;
}

.bottom-layer {
  object-fit: cover;
}

.search-btn {
  padding-left: 2em;
  padding-right: 2em;
  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-top: 1rem;
  }
}

input, select {
  border-radius: $border-radius-lg !important;
}

.card-sidebar {
  border: 0;
  .btn {
    padding-left: 2em;
    padding-right: 2em;
    font-size: 14px;
    font-weight: 600;
    @extend .btn-primary;
  }
  h5 {
    text-align: left !important;
    color: $theme1;
  }
}

.carousel-custom {
  overflow: hidden;
  padding: 1rem;
}

.btn-grid {
  @extend .btn-primary;
}

.btn-grid:hover {
  background-color: $primary-faded !important;
  border: 0  !important;
}

.main-headerless {
  @extend .pt-4;
  @extend .mt-4;
}
